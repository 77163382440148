import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './navbar.css';

const NavigationBar = () => {
    return (
      <Navbar expand="lg" bg="white" fixed="top" className="shadow-sm p-2">
        <Container>
          <Navbar.Brand>
          <Link to="/" className="header item">
            <img
              src="/img/site_logo.png"
              width="200"
              className="d-inline-block align-top mr-3"
              alt=""
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto my-2">
            <Nav.Item>
                <Link to="/about" className="mx-3 navbar-link">O Serwisie</Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    )
};

export default NavigationBar;