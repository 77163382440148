import React from 'react';
import { Col, Row } from 'react-bootstrap';

import OverviewCard from './OverviewCard';

const OverviewCardsBlock = ({statistics}) => {
	const total = statistics.total;
	const show = statistics.show;
	const hide = (total !== undefined && show !== undefined) ? total - show : undefined;
	const clicks = statistics.clicks;

	return (
		<div className="my-4">
			<Row xs={1} md={4}>
				<Col>
					<OverviewCard title='Total Articles' subtitle='saved in database' data={total}/>
				</Col>
				<Col>
					<OverviewCard title='Show' subtitle='at home feed' data={show} />
				</Col>
				<Col>
					<OverviewCard title='Hide' subtitle='from viewers' data={hide} />
				</Col>
				<Col>
					<OverviewCard title='Clicks' subtitle='on articles' data={clicks} />
				</Col>
			</Row>
		</div>
	)
}

export default OverviewCardsBlock;