import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './services/history';

import Home from './components/home/Home';
import About from './components/about/About';
import Dashboard from './components/dashboard/Dashboard';

const App = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route path="/about" component={About}/>
				<Route path="/dashboard" component={Dashboard}/>
				<Route path="/" component={Home}/>
			</Switch>
		</Router>
	);
}

export default App;