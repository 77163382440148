import React from 'react';
import { Card, Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as firebaseAPI from '../../firebase/firebase';
import './articles.css';


const ArticleGridViewItem = ({article, clicks}) => {

	const convertDateFormat = (date) => {
		// Trick: in datebase we keep date in (YYYY-MM-DD) format for easier sorting,
		// but in client UI we convert date format for better expierence
		const year = date.substring(0, 4);
		const month = date.substring(5, 7);
		const day = date.substring(8, 10);
		const location = 'pl-PL';
		const options = {dateStyle: 'long'};

		let publishDate = new Date(year, (month - 1), day);
		publishDate = publishDate.toLocaleDateString(location, options);

		return publishDate;
	};

	const updateClickStats = () => {
		firebaseAPI.updateClicksStats();
	}

	return (
		<Col>
			<Card id={article.id || ''}  border="light" className="article-grid-card hover-scale shadow mb-4 rounded">
				<a href={article.url} target="blank">
					<Card.Img variant="top" src={article.thumbnail} className="article-grid-thumbnail rounded"/>
				</a>
				<Card.Body className="d-flex flex-column">
					<a href={article.url} target="blank">
						<Card.Title className="article-grid-item-title">{article.title}</Card.Title>
					</a>
					<Card.Text className="text-muted mt-2">
						{article.favicon && <img src={article.favicon} style={{ width: '1rem', height: 'auto' }} className="mr-2 align-middle" alt=""/>}
						<span className="mr-2 align-middle">{article.publisher} <span>&#8226;</span> {convertDateFormat(article.date)}</span>
					</Card.Text>
					<Card.Text className="article-grid-item-description">{article.description}</Card.Text>
					<Button 
						className="mt-auto" 
						variant="outline-primary"
						href={article.url}
						target="blank"
						onClick={updateClickStats}
					>
						<span>Przejdź do artykułu</span>
						<FontAwesomeIcon icon={faChevronRight} className="ml-3"/>
					</Button>
				</Card.Body>
			</Card>
		</Col>
  )
};

export default ArticleGridViewItem;