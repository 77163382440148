import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import * as firebaseAPI from '../../../firebase/firebase';

const DeleteArticle = ({articleId}) => {

	const onDelete = () => {
		let result= window.confirm("Are you sure you want to delete this item?");

		if (result) {
				firebaseAPI.deleteDataFromCollection('articles', articleId)
				.then(res => {
					// update overview stats
					firebaseAPI.updateStatisticDocument('delete');
					window.location.reload();
				})
				.catch(error => {
					console.error(error);
				});
		}
	}

	return (
		<Button variant="danger" size="sm" onClick={onDelete}>
			<FontAwesomeIcon icon={faTrashAlt} />
		</Button>
	)
}

export default DeleteArticle;