import React from 'react';
import * as firebaseAPI from '../../firebase/firebase';
import { Container, Row, Col, Button, Image, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faFrown} from '@fortawesome/free-solid-svg-icons';

import NavigationBar from '../navbar/NavigationBar';
import Footer from '../footer/Footer';
import NewArticle from './actions/NewArticle';
import EditableArticlesList from './table/TableArticlesList';
import OverviewCardsBlock from './overview/OverviewCardBlock';

class Dashboard extends React.Component {
		constructor() {
			super();

			this.state = {
				statistics: {}, //overview data
				articles: [],
				orderBy: 'desc', //descending or ascending
        limit: 12,
        lastVisible: null,
        loading: false,
        refreshing: false,
				loadMoreData: true,
				user: null, //authentication
				isAdmin: false, //authentication
			};

			this.login = this.login.bind(this);
			this.logout = this.logout.bind(this); 
			this.changeArticeOrder = this.changeArticeOrder.bind(this);
		}
  
    componentDidMount() {
			firebaseAPI.auth.onAuthStateChanged((user) => {
				if (user && this.checkIsAdmin(user)) {
					this.setState({ 
						user: user,
						isAdmin: true
					}, this.retrieveData());
				} else {
					this.logout();
				}
			});
		}
		
		login() {
			firebaseAPI.auth.signInWithPopup(firebaseAPI.provider) 
			.then((result) => {
				const user = result.user;
				if(this.checkIsAdmin(user)) {
					this.setState({
						user: user,
						isAdmin: true
					});
				} else {
					this.logout();
					alert('Unauthorized: Access is denied!');
				}
			});
		}

		logout() {
			firebaseAPI.auth.signOut()
			.then(() => {
				this.setState({
					articles: [],
					lastVisible: null,
					loading: false,
					refreshing: false,
					loadMoreData: true,
					user: null,
					isAdmin: false
				});
			});
		}

		checkIsAdmin(user) {
			return (user && user.email === 'ohbalan@gmail.com');
		}

    retrieveData() {
			// Set State: Loading
			this.setState({
				loading: true
			});
				
			firebaseAPI.getDocumentFromCollection('articles', this.state.orderBy, this.state.limit)
			.then((data) => {
				this.setState({ 
					articles: data,
					loading: false
				});

				this.retrieveOverviewData();
			})
			.catch(error => {
				console.log(error);
			});
		}
  
		retrieveMore() {
			// Set State: Refreshing
			this.setState({
				refreshing: true
			});
			console.log('Retrieving additional Data');

			firebaseAPI.getMoreDocumentFromCollection('articles', this.state.orderBy, this.state.limit)
			.then((data) => {
				setTimeout(() => {
					// Set State
					this.setState({ 
						articles: [...this.state.articles, ...data],
						refreshing: false
					});
				}, 300);
			})
			.catch(error => {
				// Set State: Refreshing
				this.setState({
					refreshing: false,
					loadMoreData: false
				});
				console.log(error);
			})
		}
		
		// Get stats for overview
		retrieveOverviewData() {
			firebaseAPI.getStatisticDocument()
			.then(data => {
				this.setState({ 
					statistics: data
				});
			})
		}

		changeArticeOrder(event) {
      if (event.target.value === this.state.orderBy) {
        return;
      }
      
      this.setState({ 
        orderBy: event.target.value
      }, () => {
        // if order changed retrieve data again
        this.retrieveData();
      })
    }

    render() {
			return (
				<div>
					<NavigationBar />
					<Container style={{marginTop: '6em'}}>
						<Row>
							<Col md={8}>
								{this.state.isAdmin && <h2>Dashboard</h2>}
							</Col>
							<Col md={4} className="text-right">
								{this.state.user ?
									<div>
										<Image src={this.state.user.photoURL} style={{ width: '30px'}} roundedCircle />
										<span className="mx-2">{this.state.user.displayName}</span>
										<Button variant="outline-primary" size="sm" onClick={this.logout}>Log Out</Button>                
									</div>
									:
									<Button variant="outline-primary" size="sm" onClick={this.login}>Log In</Button>              
								}
							</Col>
						</Row>
						{
							(this.state.isAdmin && !this.state.loading) ?
							<div>
								<NewArticle history={this.state.statistics.history}/>
								<OverviewCardsBlock statistics={this.state.statistics} />
								<div className="mb-2 text-right">
								<Form.Control as="select" size="sm" style={{width: '300px'}} value={this.state.orderBy} onChange={this.changeArticeOrder} custom>
									<option value="desc">Sortuj od najnowsze</option>
									<option value="asc">Sortuj od najstarsze</option>
								</Form.Control>
								</div>
								<EditableArticlesList articles={this.state.articles} />
								{/* Load More Button - Show only when there are data to load */}
								{
									(this.state.limit && this.state.loadMoreData) ?
									<div className="text-center">
										<Button 
											variant="outline-primary"
											disabled={this.state.refreshing}
											onClick={!this.state.refreshing ? this.retrieveMore.bind(this) : null}
										>
											<span className="px-4">
												{this.state.refreshing ? <span>Ładowanie…</span> : <span>Zobacz więcej</span>}
												{this.state.refreshing ? 
													<Spinner animation="border" variant="primary" size="sm" className="mx-2" /> : <FontAwesomeIcon icon={faAngleDoubleDown} className="mx-2" />
												}
											</span>
										</Button>
									</div> : <p className="text-center text-muted">Nie ma więcej artykułów <FontAwesomeIcon icon={faFrown} className="mx-2" /></p>
								}
							</div>
							:
							<div className="text-center">
								<Spinner animation="border" variant="primary" size="sm" />
							</div>
						}
					</Container>
					<Footer />
				</div>
			)
    };
}

export default Dashboard;