import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

const OverviewCard = ({title, subtitle, data}) => {
	return (
		<Card border="light" className="shadow mb-2 rounded">
			<Card.Body>
				<Row>
					<Col md="8">
						<Card.Title>{title}</Card.Title>
						<span className="text-muted">{subtitle}</span>
					</Col>
					<Col md="4 text-center">
						<h3>{data}</h3>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default OverviewCard;