import React from 'react';
import { Table } from 'react-bootstrap';

import TableArticleItem from './TableArticleItem'

const TableArticlesList = ({articles = []}) => {
	return (
		<div>
			{
				articles.length > 0 &&
				<Table bordered hover className="table-article-list shadow mb-4 rounded responsive">
					<thead>
						<tr>
							<th scope="col" width="45%">Article Title</th>
							<th scope="col" width="20%">Publisher</th>
							<th scope="col" width="15%">Publish Date</th>
							<th scope="col" width="5%">Display</th>
							<th scope="col" width="15%">Actions</th>
						</tr>
					</thead>
					<tbody>
						{ articles.map((article, index) => {
								return <TableArticleItem article={article} key={index} />
						})}
					</tbody>
				</Table>
			}
		</div>
	);
}

export default TableArticlesList;