import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import NavBar from '../navbar/NavigationBar';
import Footer from '../footer/Footer';
import ContactForm from '../form/ContactForm';

const About = () => {
	return (
		<div>
			<NavBar />
			<Container style={{marginTop: '6em'}}>
				<Row>
					<Col md={6} xs={12}>
						<div className="my-4">
						<h5>Po co jest ten serwis?</h5>
						<p>
						Serwis ten powstał w celu zebrania w jednym miejscu jak największej liczby artykułów ze stron, serwisów oraz blogów 
						na temat społeczności wietnamskiej w Polsce oraz wszystkiego co związane z Wietnamem.
						</p>
						<p>W formie agregatora wiadomości chcemy pokazać pełny obraz Wietnamczyków, którzy stanowią jedną z największych 
							i najaktywniejszych mniejszości narodowych w Polsce. W ten sposób każdy kto jest zainteresowany może w łatwy i przyjazny sposób 
							dowiedzieć się więcej na temat diaspory wietnamskiej.
						</p>
						<p>
						Jeśli masz jakieś uwagi i sugestie odnośnie działania serwisu lub zgłosić artykuł, który według ciebie powinien się tu znaleźć - możesz napisać do nas 
						przez specjalnie przygotowany formularz, który znajdziesz obok.
						</p>
						</div>
					</Col>
					<Col md={6} xs={12}>
						<ContactForm className="my-2"/>
					</Col>
				</Row>
			</Container>
			<Footer/>
		</div>
	)
}

export default About;