import React, { useState } from 'react';
import * as firebaseAPI from '../../../firebase/firebase';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faImage } from '@fortawesome/free-solid-svg-icons';

import ArticleCard from '../../articles/ArticleGridViewItem';

const EditArticle = ({article}) => {
	const [data, setData] = useState(article);

	const [showStatus, setShowStatus] = useState(false);

	const onEdit = (e) => {
		e.preventDefault();
		const article = {...data};

		if (article) {
			firebaseAPI.updateDocumentInCollection('articles', article)
			.then(res => {
				close();
				window.location.reload();
			})
			.catch(error => {
				console.error(error);
			});
		}

		close();
	}

	const changeArticleVisibility = (e) => {
			const oldValue = data.display;

			if(oldValue === e.target.checked) {
				return;
			}

			setData({...data, display: e.target.checked})
			// update overview stats
			if(oldValue && !e.target.checked) {
				firebaseAPI.updateStatisticDocument('hide');
			}else if(!oldValue && e.target.checked) {
				firebaseAPI.updateStatisticDocument('show');
			}
	}

	const show = () => {
		setShowStatus(true);
	}

	const close = () => {
		setShowStatus(false);
	}

	return (
		<span>
		<Button variant="success" size="sm" onClick={show} className="mx-1">
			<FontAwesomeIcon icon={faEdit} />
		</Button>

		<Modal 
			size="xl"
			show={showStatus}
			onHide={close}
			centered
		>
		<Modal.Header closeButton>
			<Modal.Title>Edit Article</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form className="mt-2">
				<Row>
					<Col md="8">
						<Form.Group as={Row}>
							<Form.Label column md="2"><span>Link:</span></Form.Label>
							<Col md="10">
								<Form.Control type="text" name="url" value={data.url} onChange={e => setData({...data, url: e.target.value})} />
							</Col>
						</Form.Group>
						{ data && 
							<div>
								<Form.Group as={Row}>
									<Form.Label column md="2"><span>Title:</span></Form.Label>
									<Col md="10">
										<Form.Control type="text" name="title" value={data.title} onChange={e => setData({...data, title: e.target.value})} />
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column md="2"><span>Thumbnail:</span></Form.Label>
									<Col md="10">
										<Form.Control type="text" name="thumbnail" value={data.thumbnail} onChange={e => setData({...data, thumbnail: e.target.value})} />
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column md="2"><span>Favicon:</span></Form.Label>
									<Col md="10">
										<Form.Control type="text" name="favicon" value={data.favicon} onChange={e => setData({...data, favicon: e.target.value})} />
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column md="2"><span>Publisher:</span></Form.Label>
									<Col md="4">
										<Form.Control type="text" name="publisher" value={data.publisher} onChange={e => setData({...data, publisher: e.target.value})} />
									</Col>
									<Form.Label column md="2"><span>Publish date:</span></Form.Label>
									<Col md="4">
										<Form.Control type="text" name="date" value={data.date} onChange={e => setData({...data, date: e.target.value})} placeholder="YYYY-MM-DD"/>
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column md="2"><span>Description:</span></Form.Label>
									<Col md="10">
										<Form.Control as="textarea" rows="8" name="description" value={data.description} onChange={e => setData({...data, description: e.target.value})}/>
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column md="2"></Form.Label>
									<Col md="10">
										<Form.Check
											type="switch"
											name="display"
											id="custom-switch"
											label={<span>Hide / Show at home page</span>}
											checked={data.display && true}
											onChange={(e) => {changeArticleVisibility(e)}}
										/>
									</Col>
								</Form.Group>
							</div>
						}
					</Col>
					<Col md="4">
						<Form.Group>
							{ data ? <ArticleCard article={data} /> : <span className="text-muted"><FontAwesomeIcon icon={faImage} className="mr-2" />Article preview...</span>}
						</Form.Group>
					</Col>
				</Row>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="ligth" onClick={close}><span>Cancel</span></Button>
			<Button variant="primary" type="submit" onClick={onEdit}><span>Save</span></Button>
		</Modal.Footer>
		</Modal>
		</span>
	)
}

export default EditArticle;