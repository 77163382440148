import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import * as emailjs from 'emailjs-com';

const ContactForm = () => {

  const [email, setEmail] = useState({
		emailAddress: '',
		message: '',
		isSend: false
	});

	const onSubmit = (e) => {
    e.preventDefault()
		const { emailAddress, message } = email;
	
		let templateParams = {
      from_name: emailAddress,
      to_name: 'ohbalan@gmail.com,',
      message_html: message
		 }

     emailjs.send(
      'gmail',
      'template_LiqchM54',
       templateParams,
      'user_7Jxa0TreoMKc6xS1c3Wn7'
     )
		 
		 setEmail({ isSend: true});
	}
    return (
      <Card border="light" className="shadow rounded">
        <Card.Body>
        <h5>Formularz kontaktowy</h5>
        <Card.Text className="mt-2 text-muted">
        Możesz się skontaktować za pomocą poniższego formularza.
        </Card.Text>
          {!email.isSend ?
            <Form onSubmit={e => {onSubmit(e)}} method="POST">
              <Form.Group>
                <Form.Control type="email" placeholder="Podaj swój email" onChange={e => setEmail({...email, emailAddress: e.target.value})} />
              </Form.Group>
              <Form.Group>
                <Form.Control as="textarea" rows="8" placeholder="Podaj treść wiadomości" onChange={e => setEmail({...email, message: e.target.value})} />
              </Form.Group>
              <div className="text-right">
                <Button variant="outline-primary" type="submit">Wyślij</Button>
              </div>
            </Form>
            :
            <span className="text-success">Wiadomość pomyślnie wysłany!</span>
          }
        </Card.Body>
      </Card>
    )
};

export default ContactForm;