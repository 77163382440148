import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as firebaseAPI from '../../firebase/firebase';
import './articles.css';

const ArticleListViewItem = ({article, clicks}) => {

  const convertDateFormat = (date) => {
      // Trick: in datebase we keep date in (YYYY-MM-DD) format for easier sorting,
      // but in client UI we convert date format for better expierence
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      const location = 'pl-PL';
      const options = {dateStyle: 'long'};

      let publishDate = new Date(year, (month - 1), day);
      publishDate = publishDate.toLocaleDateString(location, options);

      return publishDate;
  };

  const updateClickStats = () => {
		firebaseAPI.updateClicksStats();
	}

  return (
    <Card id={article.id || ''}  border="light" className="article-list-card hover-scale shadow mb-4 rounded">
      <Row noGutters="false">
        <Col md="4">
          <a href={article.url} target="blank">
            <Card.Img src={article.thumbnail} className="article-grid-thumbnail rounded"/>
          </a>
        </Col>
        <Col md="8">
          <Card.Body className="d-flex flex-column" style={{height: '100%'}}>
            <a href={article.url} target="blank">
              <Card.Title className="article-list-item-title">{article.title}</Card.Title>
            </a>
            <Card.Text className="text-muted mt-2">
              {article.favicon && <img src={article.favicon} style={{ width: '1rem', height: 'auto' }} className="mr-2 align-middle" alt=""/>}
              <span className="mr-2 align-middle">{article.publisher} | {convertDateFormat(article.date)}</span>
            </Card.Text>
            <Card.Text className="article-list-item-description">{article.description}</Card.Text>
            <Button
              className="ml-auto mr-auto mt-auto"
              style={{width: '300px'}} 
              variant="outline-primary"
              href={article.url}
              target="blank"
              onClick={updateClickStats}
            >
              Przejdź do artykułu
              <FontAwesomeIcon icon={faChevronRight} className="ml-3"/>
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
};

export default ArticleListViewItem;