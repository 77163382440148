import * as firebase from 'firebase';
import config from './config';

// Initialize app
firebase.initializeApp(config);
// Analytics
firebase.analytics();
// Authentication
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

const db = firebase.firestore();

let lastVisible = null;

// Get document collection by order and limit
export function getDocumentFromCollection(collection, orderBy, limit) {
	return new Promise((resolve, reject) => {
		let query = db.collection(collection).orderBy('date', orderBy);
		
		//  limit the number of documents retrieved
		if(limit) {
			query = db.collection(collection).orderBy('date',orderBy).limit(limit);
		}
		
		query.get()
		.then(snapshot => {
			let allData = [];
			//Last Visible Document (Document ID To Start From For Proceeding Queries)
			if (snapshot.docs[snapshot.docs.length-1]) {
					lastVisible = snapshot.docs[snapshot.docs.length-1];
			}
			
			snapshot.forEach(doc => {
					const data = doc.data();
					data.id = doc.id; // Trick: need id to able to remove document from collection
					allData.push(data);
			});
			
			console.log('Data retrieved');
			resolve(allData);
		})
		.catch(error => {
			console.log('Error getting data from collection:', error);
		});
	})
}

// get more document from collection for 'load more' feature
export function getMoreDocumentFromCollection(collection, orderBy, limit) {
	return new Promise((resolve, reject) => {
		const query = db.collection(collection).orderBy('date',orderBy).startAfter(lastVisible).limit(limit);
		
		query.get()
		.then(snapshot => {
			let allData = [];
			//Last Visible Document (Document ID To Start From For Proceeding Queries)
			if (snapshot.docs[snapshot.docs.length-1]) {
				lastVisible = snapshot.docs[snapshot.docs.length-1];
			} else {
				reject('No more data to retrieve');
			}
			
			snapshot.forEach(doc => {
				const data = doc.data();
				data.id = doc.id; // Trick: need id to able to remove document from collection
				allData.push(data);
			});
			
			console.log('More data retrieved');
			resolve(allData);
		})
		.catch(error => {
			console.log('Error getting data from collection:', error);
		});
	})
}

// Add document to collection
export async function addDocumentToCollection(collection, document) {
	if (collection && document) {
		await db.collection(collection).add(document);
		console.log('Data saved');
	}
}

// Update document in collection
export async function updateDocumentInCollection(collection, document) {
	if(collection && document) {
		await db.collection(collection).doc(document.id).update(document);
		console.log('Data updated');
	}
}

// Delete document from collection
export async function deleteDataFromCollection(collection, documentId) {
	if (collection && documentId) {
		await db.collection(collection).doc(documentId).delete();
		console.log('Deleted document: ', documentId);
	}
}

// Statistics collection for overview feature in dashboard
const statisticRef = db.collection('statistics').doc('stats');
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

export function getStatisticDocument() {
	return new Promise((resolve, reject) => {
		statisticRef.get().then(function(doc) {
			if (doc.exists) {
					resolve(doc.data());
			} else {
					reject('No such document!')
			}
		}).catch(function(error) {
			console.log('Error getting document:', error);
		});
	})
}

export function updateStatisticDocument(operation) {
	if(operation) {
		switch(operation) {
			case 'create':
				statisticRef.update({ 
						total: increment,
						show: increment
				});
				break;
			case 'delete':
				statisticRef.update({ 
						total: decrement,
						show: decrement
				});
				break;
			case 'show':
				statisticRef.update({ show: increment});
				break;
			case 'hide':
				statisticRef.update({ show: decrement});
				break;
			default:
				break;
		}
	}
}

export function updateClicksStats() {
	statisticRef.update({ clicks: increment});
}


export function updateArticleHistoryArray(url) {
	if(url) {
		statisticRef.update({ 
			history: firebase.firestore.FieldValue.arrayUnion(url)
	});
	}
}