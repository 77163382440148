import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faImage } from '@fortawesome/free-solid-svg-icons';
import * as firebaseAPI from '../../../firebase/firebase';

import ArticleGridViewItem from '../../articles/ArticleGridViewItem';

function NewArticle ({history}) {
    const [text, setText] = useState('');
    const [data, setData] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
      updateArticlePreview();
    });

    const fetchLinkData = async(event) => {
      setLoading(true)
      event.preventDefault();

      if(text) {
        const url = 'https://us-central1-wietnamczycy-86e9c.cloudfunctions.net/scraper';
        
        const response = await fetch(url, { 
          method: 'POST',
          body: JSON.stringify({ text })
        });
        
        console.log(`Submitting ${text}`);
        const data = await response.json();
        setData(data[0]);
      }

      setLoading(false);
    }

    const updateArticlePreview = () => {
      if(data) {
        document.querySelector('[name="title"]').value = data.title;
        document.querySelector('[name="thumbnail"]').value = data.thumbnail;
        document.querySelector('[name="favicon"]').value = data.favicon;
        document.querySelector('[name="publisher"]').value = data.publisher;
        document.querySelector('[name="date"]').value = data.date;
        document.querySelector('textarea').value = data.description;
        document.querySelector('[name="display"]').checked = data.display;
      }
    }

    const saveArticleToDatabase = (event) => {
      event.preventDefault();
      const article = {...data};

      if (article) {
        if(isArticleAlreadyAdded(article.url)) {
          window.confirm("Article is already added, try with another one!");
          close();
          return;
        }

        // add document to collection
        firebaseAPI.addDocumentToCollection('articles', article)
        .then(res => {
            // update overview stats
            firebaseAPI.updateStatisticDocument('create');
            if(!article.display) {
              firebaseAPI.updateStatisticDocument('hide');
            }
            // update article history array
            firebaseAPI.updateArticleHistoryArray(article.url);

            close();
            window.location.reload();
        })
        .catch(error => {
          console.error(error);
        });
      }
  };

  const isArticleAlreadyAdded = (url) => {
    if(url) {
      const filtred = history.filter(item => {
        return item === url;
      });
      return filtred.length > 0 ? true : false;
    }
  }

  const show = () => {
    setShowStatus(true);
  }

  const close = () => {
    setShowStatus(false);
    clear();
  }

  const clear = () => {
    setText('');
    setData('');
    setLoading(false);
  }

  return (
    <div className="my-4">
      <Button variant="primary" onClick={show}>
          <span><FontAwesomeIcon icon={faPlus} className="mr-1" />New Article</span>
      </Button>

      <Modal 
          size="xl"
          show={showStatus}
          onHide={close}
          centered
      >
      <Modal.Header closeButton>
          <Modal.Title>New Article</Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <Form className="mt-2">
          <Row>
            <Col md="8">
              <Form.Group as={Row}>
                <Form.Label column md="2"><span>Link:</span></Form.Label>
                <Col md="8">
                  <Form.Control type="text" value={text} onChange={ e => setText(e.target.value)} />
                </Col>
                <Col md="2">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={fetchLinkData}
                    block
                  >
                    {isLoading ? <span>Fetching...</span> : <span>Fetch</span>}
                  </Button>
                </Col>
              </Form.Group>
              { data && 
                <div>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"><span>Title:</span></Form.Label>
                    <Col md="10">
                      <Form.Control type="text" name="title" onChange={e => setData({...data, title: e.target.value})} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"><span>Thumbnail:</span></Form.Label>
                    <Col md="10">
                      <Form.Control type="text" name="thumbnail" onChange={e => setData({...data, thumbnail: e.target.value})} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"><span>Favicon:</span></Form.Label>
                    <Col md="10">
                      <Form.Control type="text" name="favicon" onChange={e => setData({...data, favicon: e.target.value})} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"><span>Publisher:</span></Form.Label>
                    <Col md="4">
                      <Form.Control type="text" name="publisher" onChange={e => setData({...data, publisher: e.target.value})} />
                    </Col>
                    <Form.Label column md="2"><span>Publish date:</span></Form.Label>
                    <Col md="4">
                      <Form.Control type="text" name="date" onChange={e => setData({...data, date: e.target.value})} placeholder="YYYY-MM-DD"/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"><span>Description:</span></Form.Label>
                    <Col md="10">
                      <Form.Control as="textarea" rows="8" name="description" onChange={e => setData({...data, description: e.target.value})} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md="2"></Form.Label>
                    <Col md="10">
                      <Form.Check
                        type="switch"
                        name="display"
                        id="custom-switch"
                        label={<span>Hide / Show at home page</span>}
                        onChange={e => setData({...data, display: e.target.checked})}
                      />
                    </Col>
                  </Form.Group>
                </div>
              }
            </Col>
            <Col md="4" className="mt-auto ">
              <Form.Group>
                { data ? <ArticleGridViewItem article={data} /> : 
                  <div className="text-center">
                    <span className="text-muted"><FontAwesomeIcon icon={faImage} className="mr-2" />Article preview...</span>
                  </div>
                }
              </Form.Group>
            </Col>
          </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ligth" onClick={close}><span>Cancel</span></Button>
          <Button variant="primary" type="submit" onClick={saveArticleToDatabase} disabled={!data}><span>Save</span></Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewArticle;