import React from 'react';

import ArticleListViewItem from './ArticleListViewItem';

const ArticlesListView = ({articles = [], clicks}) => {
	return (
		<div>
			{
				articles.length > 0 &&
				<div className="my-4 mb-5 articles-list">
					{articles.map((article, index) => {
						return article.display && <ArticleListViewItem article={article} clicks={clicks} key={index} />;
					})}
				</div>
			}
		</div>
	);
}

export default ArticlesListView;