import React from 'react';
import { Container, Col, Row, Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faThList, faAngleDoubleDown, faFrown} from '@fortawesome/free-solid-svg-icons';
import * as firebaseAPI from '../../firebase/firebase';

import NavigationBar from '../navbar/NavigationBar';
import Footer from '../footer/Footer';
import ArticlesListView from '../articles/ArticleListView';
import ArticlesGridView from '../articles/ArticleGridView';
import BottomAd from '../ads/BottomAd';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.retrieveMore = this.retrieveMore.bind(this);
    this.changeArticeOrder = this.changeArticeOrder.bind(this);
  }

  state = {
    statistics: {}, //overview data
    articles: [],
    orderBy: 'desc', //descending or ascending
    limit: 12,
    loading: false,
    refreshing: false,
    loadMoreData: true,
    showMode: 'grid',
    windowWidth: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    try {
      this.retrieveData()
    }
    catch(error) {
      console.error(error);
    }
  }

  // remove the listener
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleShowMode(mode) {
    this.setState({showMode: mode});
  }

  retrieveData() {
    // Set State: Loading
    this.setState({
      loading: true
    });
      
    firebaseAPI.getDocumentFromCollection('articles', this.state.orderBy, this.state.limit)
    .then((data) => {
      this.setState({ 
        articles: data,
        loading: false
      });

      this.retrieveOverviewData();
    });
  }

  retrieveMore() {
      // Set State: Refreshing
      this.setState({
        refreshing: true
      });
      console.log('Retrieving additional Data');

      firebaseAPI.getMoreDocumentFromCollection('articles', this.state.orderBy, this.state.limit)
      .then((data) => {
        setTimeout(() => {
          // Set State
          this.setState({ 
            articles: [...this.state.articles, ...data],
            refreshing: false
          });
        }, 300);
      })
      .catch(error => {
        // Set State: Refreshing
        this.setState({
          refreshing: false,
          loadMoreData: false
        });
        console.log(error);
      })
  }

  // Get stats for overview
  retrieveOverviewData() {
    firebaseAPI.getStatisticDocument()
    .then(data => {
      this.setState({ 
        statistics: data
      });
    })
  }

  changeArticeOrder(event) {
    if (event.target.value === this.state.orderBy) {
      return;
    }
    
    this.setState({ 
      orderBy: event.target.value
    }, () => {
      // if order changed retrieve data again
      this.retrieveData();
    })
  }

  render() {
    const { width, articles, statistics } = this.state;
    const isMobile = width <= 768;

    return (
      <div>
        <NavigationBar />
        <Container style={{marginTop: '6em'}}>
          {
            !this.state.loading ?
            <div>
              <h4>Strony <span>&#8226;</span> Serwisy <span>&#8226;</span> Blogi</h4>
              <p className="text-muted">Artykuły zebrane z całej sieci na temat społeczności wietnamskiej w Polsce oraz wszystkiego co związane z Wietnamem.</p>
              <hr/>
              <Row noGutters="false">
                <Col xl={8}  lg={6} md={6} xs={4}>
                  <Button variant="outline-primary" size="sm" className="mr-2 my-1" href="/about"><span>+ Zgłoś Artykuł</span></Button>
                </Col>
                <Col xl={3}  lg={4} md={4} xs={8} className="my-1">
                  <Form.Control as="select" size="sm" value={this.state.orderBy} onChange={this.changeArticeOrder} custom>
                    <option value="desc">Sortuj od najnowsze</option>
                    <option value="asc">Sortuj od najstarsze</option>
                  </Form.Control>
                </Col>
                {!isMobile && 
                  <Col xl={1} lg={2} md={2} xs={0} className="my-1">
                    <div className="show-mode-switch text-right">
                      <Button variant="outline-primary" size="sm" onClick={() => this.toggleShowMode('grid')} className="mx-1"><FontAwesomeIcon icon={faThLarge} /></Button>
                      <Button variant="outline-primary" size="sm" onClick={() => this.toggleShowMode('list')} className="mx-1"><FontAwesomeIcon icon={faThList} /></Button>
                    </div>
                  </Col>
                }
              </Row>
              {
                this.state.showMode === 'grid' ? <ArticlesGridView articles={articles} clicks={statistics.clicks}/> : <ArticlesListView articles={articles} clicks={statistics.clicks}/>
              }
              {/* Load More Button - Show only when there are data to load */}
              {
                this.state.loadMoreData ?
                <div className="text-center">
                  <Button 
                    variant="outline-primary"
                    disabled={this.state.refreshing}
                    onClick={!this.state.refreshing ? this.retrieveMore : null}
                  >
                    <span className="px-4">
                      {this.state.refreshing ? <span>Ładowanie…</span> : <span>Zobacz więcej</span>}
                      {this.state.refreshing ? 
                        <Spinner animation="border" variant="primary" size="sm" className="mx-2" /> : <FontAwesomeIcon icon={faAngleDoubleDown} className="mx-2" />
                      }
                    </span>
                  </Button>
                </div> : <p className="text-center text-muted">Nie ma więcej artykułów <FontAwesomeIcon icon={faFrown} className="mx-2" /></p>
              }
              {/* <BottomAd /> */}
              <Footer />
            </div>
            :
            <div className="text-center">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          }
        </Container>
      </div>
    );
  }
}

export default Home;