const config= {
	apiKey: "AIzaSyB4sqe52W8Oo0cUonhpa0T3n1_rgvL30qA",
	authDomain: "wietnamczycy-86e9c.firebaseapp.com",
	databaseURL: "https://wietnamczycy-86e9c.firebaseio.com",
	projectId: "wietnamczycy-86e9c",
	storageBucket: "wietnamczycy-86e9c.appspot.com",
	messagingSenderId: "410016695324",
	appId: "1:410016695324:web:1c30463afe05ff6aeacbd9",
	measurementId: "G-S1QS767CB5"
};

export default config;