import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

const RedirectArticle = ({url}) => {
	return (
		<Button variant="primary" size="sm" href={url} target="blank">
				<FontAwesomeIcon icon={faExternalLinkAlt} />
		</Button>
	)
}

export default RedirectArticle;