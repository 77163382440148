import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import RedirectArticle from '../actions/RedirectArticle';
import EditArticle from '../actions/EditArticle';
import DeleteArticle from '../actions/DeleteArticle';

const TableArticleItem = ({article}) => {
    return (
      <tr id={article.id}>
        <td>{article.title}</td>
        <td>{article.publisher}</td>
        <td>{article.date}</td>
        <td align="center">
          <FontAwesomeIcon className={article.display ? 'text-success' : 'text-warning'} icon={faCircle} />
        </td>
        <td align="center">
          <RedirectArticle url={article.url} />
          <EditArticle article={article} />
          <DeleteArticle articleId={article.id} />
        </td>
      </tr>
    )
};

export default TableArticleItem;