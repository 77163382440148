import React from 'react';

const Footer = () => {
    return (
      <footer className="my-5">
        <div className="text-center text-muted">
          <img
            src="/img/site_logo.png"
            width="180"
            className="mb-2"
            alt=""
          />
          <p>Copyright &copy; 2020</p>
        </div>
      </footer>
    )
};

export default Footer;