import React from 'react';
import { CardDeck, Row } from 'react-bootstrap';

import ArticleGridViewItem from './ArticleGridViewItem';

const ArticlesGridView = ({articles = [], clicks}) => {
	return (
		<div>
			{
				articles.length > 0 &&
				<CardDeck className="my-4 articles-grid">
					<Row lg={3} md={2} sm={1} xs={1} noGutters="false">
						{
							articles.map((article, index) => {
								return article.display && <ArticleGridViewItem article={article} clicks={clicks} key={index} />;
							})
						}
					</Row>
				</CardDeck>
			}
		</div>
	);
}

export default ArticlesGridView;